import React, { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import Loader from "src/shared/Loading";

const Root = (props) => {
  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Fragment>
  )
}

ReactDOM.render(<Root/>, document.getElementById('root'));