import "./App.css";
import routes from "src/routes";
import { useRoutes } from "react-router-dom";
import { Fragment } from "react";
import Loader from "./shared/Loading";

const App = () => {
    const routing = useRoutes(routes);

    return (
        <Fragment>
            {routing}
            {/* <Loader /> */}
        </Fragment>
    );
};

export default App;
