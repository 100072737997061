import React from "react";
import { Navigate } from "react-router-dom";

// import Home from "src/views/Home";
const Home = React.lazy(() => import("src/views/Home"));

const routes = [
    {
        path: "",
        element: <Home />,
    }
];

export default routes;
